import { render, staticRenderFns } from "./groupObjectDetailsLeft.vue?vue&type=template&id=27c84624&scoped=true&"
import script from "./groupObjectDetailsLeft.vue?vue&type=script&lang=js&"
export * from "./groupObjectDetailsLeft.vue?vue&type=script&lang=js&"
import style0 from "./groupObjectDetailsLeft.vue?vue&type=style&index=0&id=27c84624&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27c84624",
  null
  
)

export default component.exports