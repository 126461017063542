<!-- 开发人员: 彭雨萏 -->
<template>
  <el-dialog
    :visible="applyPersonToMemberTo"
    :title="$t('label.manageAll.forapproval')"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <el-table
        :data="applyUsersData"
        :header-cell-style="{ background: '#FAFAFA' }"
        height="200"
      >
        <!-- 用户名 -->
        <el-table-column
          :label="$t('chatter.group.userName')"
          min-width="80"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.userName }}
          </template>
        </el-table-column>
        <!-- 批准 -->
        <el-table-column
          :label="$t('label.approve')"
          min-width="80"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              class="agreeJoinInClass"
              @click="agreeJoinIn(scope.row.userName, scope.row.userid)"
            >
              {{$t('vue_label_chatter_agree_join')}}
            </div>
          </template>
        </el-table-column>
        <!-- 拒绝 -->
        <el-table-column
          :label="$t('label.refuse')"
          min-width="80"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              class="refusedJoinInClass"
              @click="refusedJoinIn(scope.row.userName, scope.row.userid)"
            >
              {{$t('vue_label_chatter_refuse_join')}}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="applyPersonToMemberCancle">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="applyPersonToMemberConfirm">
        <!-- 确定 -->
        {{ $t("label.ems.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    applyPersonToMemberTo: {
      type: Boolean,
      default: false,
    },
    applyUsersData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleClose() {
      this.$emit("applyPersonToMemberCancle");
    },
    applyPersonToMemberCancle() {
      this.$emit("applyPersonToMemberCancle");
    },
    applyPersonToMemberConfirm() {
      this.$emit("applyPersonToMemberConfirm");
    },
    agreeJoinIn(name, applyId) {
      this.$emit("agreeJoinIn", name, applyId);
    },
    refusedJoinIn(name, applyId) {
      this.$emit("refusedJoinIn", name, applyId);
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
::v-deep .el-dialog {
  width: 600px;
}
.dialog-body {
  .agreeJoinInClass,
  .refusedJoinInClass {
    &:hover {
      cursor: pointer;
      color: #006dcc;
    }
  }
}
::v-deep .el-table::before {
  height: 0;
}
</style>