<!-- 小组详情页面 -- 左侧部分详细信息 -->
<template>
  <div class="groupObjectDetailsLeft">
    <!-- 小组标题(name和type) -->
    <div class="groupDetailsTitle">{{ queryUserGroupInfoListData.name }}</div>
    <!-- 小组头像 -->
    <div class="groupDetailsImg">
      <img :src="queryUserGroupInfoListData.image" alt="" />
      <div
        class="editGroupImgClass"
        @click="editGroupImg"
        v-show="queryUserGroupInfoListData.loginUserGroupRole == 'manager'"
      >
        <svg class="icon xaingjiImg" aria-hidden="true" slot="reference">
          <use href="#icon-xaingji"></use>
        </svg>
      </div>
    </div>
    <!-- 小组备注 -->
    <div class="groopNotes">
      {{$t('label.description')}}：{{ queryUserGroupInfoListData.description }}
    </div>
    <!-- 审批成员管理 --(点击标题出审批成员弹框:新写) -->
    <div class="applyGroupPeople" @click="applymanagement">
      {{$t('label.manageAll.forapproval')}}({{ queryUserGroupInfoListData.applyUserNumber }})
    </div>
    <!-- 小组成员管理 --(点击标题出成员管理弹框):复用 -->
    <div class="groupMember">
      <div class="groupMemberTitle">
        <div>{{$t('vue_label_chatter_team_member')}}({{ queryUserGroupInfoListData.groupUserNumber }})</div>
        <div @click="groupmanagement">{{$t('label.campaignmember.main.manageMember')}}</div>
      </div>
      <div class="groupMemberShow">
        <div
          class="memberOne"
          v-for="(item, index) in queryUserGroupInfoListData.groupusers"
          :key="index"
        >
          <!-- item.userName 成员名称 -->
          <!-- item.userstatus == normal 成员状态:正常 -->
          <!-- item.userrole 成员角色: manager--管理员 -->
          <!-- item.userid 成员ID -->
          <img
            :src="`${imgUrlCookie}/chatterzone.action?m=showPhoto&userid=${item.userid}&binding=${tokenCookie}`"
            alt=""
          />
          <div @click="jumpUserDetails(item.userid)">{{ item.userName }}</div>
        </div>
      </div>
    </div>
    <!-- 成员管理弹框 -->
    <MembersManage
      :membersManageTo="membersManageTo"
      @membersManageCancel="membersManageCancel"
      @membersManageConfirm="membersManageConfirm"
      :groupId="queryGroupId"
      :queryUserResData="queryUserResData"
      :createdUserId="queryUserId"
      :userListData="userListData"
      :createdUserIdGroup="queryGroupManagerUserId"
      @clearKeyWord="clearKeyWord"
      @getNewPerson="getNewPerson"
      @loadMore="loadMore"
      :loading="loading"
    />
    <!-- 审批成员管理框 -->
    <applyPersonToMember
      :applyPersonToMemberTo="applyPersonToMemberTo"
      @applyPersonToMemberConfirm="applyPersonToMemberConfirm"
      @applyPersonToMemberCancle="applyPersonToMemberCancle"
      :applyUsersData="queryUserGroupInfoListData.applyUser"
      @agreeJoinIn="agreeJoinIn"
      @refusedJoinIn="refusedJoinIn"
    />
    <!-- 同意加入弹框 -->
    <AgreeJoinIn
      :agreeJoinInTo="agreeJoinInTo"
      @agreeJoinInConfirm="agreeJoinInConfirm"
      @agreeJoinInCancel="agreeJoinInCancel"
      :name="applyName"
    />
    <!-- 拒绝加入弹框 -->
    <RefusedJoinIn
      :refusedJoinInTo="refusedJoinInTo"
      @refusedJoinInConfirm="refusedJoinInConfirm"
      @refusedJoinInCancel="refusedJoinInCancel"
      :name="applyName"
    />
    <!-- 修改小组头像 -->
    <EditPicture
      :editPictureTo="editPictureTo"
      :groupId="queryGroupId"
      :oldImg="queryUserGroupInfoListData.image"
      @editGroupImgCancel="editGroupImgCancel"
      @editGroupImgConfirm="editGroupImgConfirm"
    />
  </div>
</template>

<script>
import {
  joinGroup, //同意审批(normal),拒绝(rejected)
  queryGroupManager, //查询CCChat小组成员
  queryUser, //查询CCChat小组用户详细信息
  uploadGroupImage, //上传小组头像
} from "../../api.js";
import VueCookies from "vue-cookies";
import MembersManage from "@/views/colleagueCircle/components/dynamic-applyFor/MembersManage";
import applyPersonToMember from "@/views/group/groupDetails/components/applyPersonToMember.vue";
import AgreeJoinIn from "@/views/colleagueCircle/components/dynamic-applyFor/AgreeJoinIn";
import RefusedJoinIn from "@/views/colleagueCircle/components/dynamic-applyFor/RefusedJoinIn";
import EditPicture from "@/views/colleagueCircle/components/dynamic-message/EditPicture";
export default {
  components: {
    MembersManage,
    applyPersonToMember,
    AgreeJoinIn,
    RefusedJoinIn,
    EditPicture,
  },
  props: {
    //小组详细信息列表
    queryUserGroupInfoList: {
      type: Object,
      default: () => ({}),
    },
    // 小组ID
    queryGroupId: {
      type: String,
      default: "",
    },
  },
  watch: {},
  mounted() {
    this.tokenCookie = this.$CCDK.CCToken.getToken(); //token
    this.imgUrlCookie = VueCookies.get("domainName"); //获取域名
    this.$bus.$on("getMembersOrUsers", this.groupmanagement);
  },
  destroyed() {
    this.$bus.$off("getMembersOrUsers",this.groupmanagement);
  },
  data() {
    return {
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      membersManageTo: false, //成员管理弹框
      queryUserResData: [], //CCChat所有用户
      queryUserId: "", //CCChat所有用户中 -- 当前登录用户ID
      userListData: [], //CCChat小组成员
      queryGroupManagerUserId: "", //CCChat小组成员 -- 当前登录用户ID
      pageUserNum: "1", //成员管理框Num
      pageUserSize: "10", //成员管理框Size
      keyword: "", //搜索的关键词
      applyPersonToMemberTo: false,
      agreeJoinInTo: false,
      refusedJoinInTo: false,
      applyName: "", //需审批加入的人员名称
      applyId: "", //需审批加入的人员ID
      editPictureTo: false, //修改小组头像弹框
      loading: false,
      queryUserGroupInfoListData:this.queryUserGroupInfoList
    };
  },
  methods: {
    //打开修改小组头像弹框
    editGroupImg() {
      this.editPictureTo = true;
    },
    //关闭或取消修改小组头像弹框
    editGroupImgCancel() {
      this.editPictureTo = false;
    },
    //确定修改小组头像
    async editGroupImgConfirm(uploadObj, dataFile) {
      this.editPictureTo = false;
      let formData = new FormData();
      // 未上传图片时不调用上传头像接口
      if (JSON.stringify(dataFile) !== "{}") {
        formData.append("file", dataFile);
        formData.append("contJson", JSON.stringify(uploadObj));
        //上传小组头像
        await uploadGroupImage(formData);
      }
      this.queryUserGroupInfoListData.image = `${VueCookies.get(
        "domainName"
      )}/chatterGroup.action?m=showGroupImage&id=${
        this.queryGroupId
      }&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`;
      this.editPictureTo = false;
      this.$bus.$emit("getQueryUserGroupInfoBus");
    },
    // 审批成员管理框打开
    applymanagement() {
      this.applyPersonToMemberTo = true;
    },
    // 审批成员管理框取消或关闭
    applyPersonToMemberCancle() {
      this.applyPersonToMemberTo = false;
    },
    //同意加入弹框打开
    agreeJoinIn(name, applyId) {
      this.applyName = name;
      this.applyId = applyId;
      this.agreeJoinInTo = true;
    },
    // 同意加入弹框取消或关闭
    agreeJoinInCancel() {
      this.agreeJoinInTo = false;
    },
    // 确认同意加入
    async agreeJoinInConfirm() {
      this.agreeJoinInTo = false;
      await joinGroup({
        groupId: this.queryGroupId,
        userId: this.applyId,
        joinType: "normal",
      });
      this.$bus.$emit("getQueryUserGroupInfoBus");
    },
    // 拒绝加入弹框打开
    refusedJoinIn(name, applyId) {
      this.applyName = name;
      this.applyId = applyId;
      this.refusedJoinInTo = true;
    },
    // 拒绝加入弹框取消或关闭
    refusedJoinInCancel() {
      this.refusedJoinInTo = false;
    },
    // 确认拒绝
    async refusedJoinInConfirm() {
      this.refusedJoinInTo = false;
      await joinGroup({
        groupId: this.queryGroupId,
        userId: this.applyId,
        joinType: "rejected",
      });
      this.$bus.$emit("getQueryUserGroupInfoBus");
    },
    // 完成审批
    applyPersonToMemberConfirm() {
      this.applyPersonToMemberTo = false;
      // this.$bus.$emit("getQueryUserGroupInfoBus");
    },
    //点击小组成员跳转到对应的详情（用户详情页面）
    jumpUserDetails(id) {
      this.$router.push(`/commonObjects/detail/${id}/DETAIL`);
    },
    // 成员管理弹框
    async groupmanagement() {
      this.loading = true;
      this.membersManageTo = true;
      //查询CCChat所有用户
      let queryUserRes = await queryUser({
        groupId: this.queryGroupId,
        keyword: this.keyword,
        pageNum: this.pageUserNum,
        pageSize: this.pageUserSize,
      });
      this.queryUserResData = queryUserRes.data.user_l;
      this.queryUserId = queryUserRes.data.userId;
      //查询CCChat小组成员
      let queryGroupManagerRes = await queryGroupManager({
        groupId: this.queryGroupId,
        mark: "all",
        keyword: this.keyword,
        pageNum: this.pageUserNum,
        pageSize: this.pageUserSize,
      });
      this.userListData = queryGroupManagerRes.data.userList;
      this.queryGroupManagerUserId = queryGroupManagerRes.data.userId;
      this.loading = false;
    },
    // 取消
    membersManageCancel() {
      this.membersManageTo = false;
    },
    // 确认
    membersManageConfirm() {
      this.membersManageTo = false;
      this.$bus.$emit("getQueryUserGroupInfoBus");
    },
    //清空关键字
    clearKeyWord() {
      this.keyword = "";
    },
    //搜索
    getNewPerson(newPersonKeyword) {
      this.keyword = newPersonKeyword;
      this.groupmanagement();
    },
    //滚动加载
    loadMore() {
      this.pageUserSize = Number(this.pageUserSize) + 5;
      this.groupmanagement();
    },
  },
};
</script>
<style lang='scss' scoped>
.groupObjectDetailsLeft {
  padding: 20px;
  color: #666666;
  // 小组标题
  .groupDetailsTitle {
    text-align: center;
    font-size: 17px;
  }
  // 小组头像
  .groupDetailsImg {
    margin: 10px 0;
    text-align: center;
    position: relative;
    img {
      width: 160px;
      height: 160px;
    }
    .editGroupImgClass {
      position: absolute;
      right: 12px;
      bottom: 0;
      .xaingjiImg {
        width: 40px;
        height: 40px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  //备注
  .groopNotes {
    font-size: 14px;
    height: 63px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; // 控制多行的行数
    -webkit-box-orient: vertical;
  }
  // 审批
  .applyGroupPeople {
    font-size: 14px;
    margin-top: 10px;
    &:hover {
      cursor: pointer;
      color: #006dcc;
    }
  }
  // 小组成员
  .groupMember {
    overflow: auto;
    .groupMemberTitle {
      padding: 10px 0;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      div:last-of-type {
        &:hover {
          cursor: pointer;
          color: #006dcc;
        }
      }
    }
    .groupMemberShow {
      height: 238px;
      overflow: auto;
      .memberOne {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        img {
          width: 26px;
          height: 26px;
          // vertical-align: middle;
          border: 1px solid #ccc;
        }
        div {
          font-size: 12px;
          color: #006dcc;
          margin-left: 10px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>