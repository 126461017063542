<!-- 小组详情页 -- 路由: groupDetails  -->
<template>
  <div class="groupObjectDetails">
    <!-- 左侧详细信息 -->
    <groupObjectDetailsLeft
      :queryUserGroupInfoList="queryUserGroupInfoList"
      :queryGroupId="queryGroupId"
      v-loading="loading"
    />
    <!-- CCChat -->
    <dynamic-public
      :menuSelectStatus="queryUserGroupInfoList"
      :changeGroupId="queryGroupId"
      postShowType="6"
      :isStatus="isStatus"
      :IsShowPostCpm="false"
    ></dynamic-public>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
//api
import {
  queryUserGroupInfo, //获取CCChat小组详细信息
} from "../api.js";

import groupObjectDetailsLeft from "./components/groupObjectDetailsLeft";
import dynamicPublic from "@/views/colleagueCircle/components/dynamic-public.vue";
export default {
  components: {
    groupObjectDetailsLeft,
    dynamicPublic,
  },
  data() {
    return {
      queryGroupId: this.$route.query.id, //小组ID
      isStatus: this.$route.query.isStatus, //当前登录者与小组的关系
      queryUserGroupInfoList: {}, //小组详细信息对象
      loading: false,
    };
  },
  mounted() {
    this.$bus.$on("getQueryUserGroupInfoBus", this.getQueryUserGroupInfo);
    this.getQueryUserGroupInfo();
  },
  destroyed() {
    this.$bus.$off("getQueryUserGroupInfoBus", this.getQueryUserGroupInfo);
  },
  computed: {},
  watch: {},
  methods: {
    async getQueryUserGroupInfo() {
      this.loading = true;
      let queryUserGroupInfoRes = await queryUserGroupInfo({
        groupId: this.queryGroupId,
      });
      this.queryUserGroupInfoList = queryUserGroupInfoRes.data.list[0];
      this.queryUserGroupInfoList.image = `${VueCookies.get(
        "domainName"
      )}/chatterGroup.action?m=showGroupImage&id=${
        this.queryGroupId
      }&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.groupObjectDetails {
  height: 100%;
  padding: 10px;
  font-size: 12px !important;
  color: #080707 !important;
  display: flex;
  .groupObjectDetailsLeft {
    width: 220px;
    background-color: white;
    border-radius: 3px;
  }
  .dynamic-public {
    width: calc(100% - 230px);
    border-radius: 3px;
    margin-left: 10px;
    background-color: white;
    overflow-y: auto;
    // border-left: 1px solid #C2D9F8 ;
  }
}
</style>
